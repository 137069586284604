import './banner.scss';
import 'slick-carousel';

export default async () => {
	$( '.js-banner-slider' ).slick( {
		rows: 0,
		fade: true,
		dots: false,
		autoplay: true,
		autoPlaySpeed: 5000,
		arrows: false,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		pauseOnFocus: false,
		pauseOnHover: false,
		lazyLoad: 'anticipated',
	} );

	$( '.js-anchor-link' ).click( function ( e ) {
		e.preventDefault();
		$( 'html, body' ).animate( {
			scrollTop: $( this ).offset().top - 38,
		}, 800 );
	} );
};
